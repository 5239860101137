<template>
  <div class="multiview-table">
    <div
      v-if="loading"
      class="multiview-table__loader"
    >
      <Spinner />
    </div>
    <div
      v-else-if="!eventIds.length"
      class="multiview-table__no-data"
    >
      <EmptyState
        message="No events"
      />
    </div>
    <div :class="['multiview-table__row', 'multiview-table__row--is-header']">
      <div
        :class="['multiview-table__group', 'multiview-table__group--type-left-actions']"
      >
        <div class="multiview-table__cell">
          &nbsp;
        </div>
      </div>
      <div
        :class="[
          'multiview-table__group',
          `multiview-table__group--type-EVENT`
        ]"
      >
        <div class="multiview-table__cell">
          <SortButton />
          Events
        </div>
      </div>
      <div
        :class="[
          'multiview-table__group',
          `multiview-table__group--type-MARKETS`
        ]"
      >
        <div class="multiview-table__cell">
          Moneyline
        </div>
        <div class="multiview-table__cell">
          Spread
        </div>
        <div class="multiview-table__cell">
          Total
        </div>
      </div>
      <div
        :class="[
          'multiview-table__group',
          `multiview-table__group--type-PARAMS`
        ]"
      >
        <div class="multiview-table__cell">
          Parameters
        </div>
      </div>
      <div
        :class="['multiview-table__group', 'multiview-table__group--type-right-actions']"
      >
        <div class="multiview-table__cell">
          &nbsp;
        </div>
      </div>
    </div>
    <div
      v-for="eventId in sortedEventIds"
      :key="eventId"
      :class="[
        'multiview-table__row',
        { 'multiview-table__row--is-active': eventId === drawerEventId },
        { 'multiview-table__row--is-large': isBaseballEvent(eventId) },
        { 'multiview-table__row--is-mid': isFootballEvent(eventId) },
      ]"
    >
      <div
        :class="[
          'multiview-table__group',
          'multiview-table__group--type-left-actions',
          { 'multiview-table__group--is-suspended': isEventSuspended(eventId) },
          { 'multiview-table__group--is-disabled': isEventDisabled(eventId) },
          { 'multiview-table__group--is-disabled': isEventHidden(eventId) },
        ]"
      >
        <PinButton
          :pinned="isEventPinned(eventId)"
          @pin="pinEvent(eventId)"
          @unpin="unpinEvent(eventId)"
        />
        <div
          v-if="isEventDisabled(eventId)"
          class="disabled-event-indicator"
        >
          Disabled
        </div>
        <div
          v-else-if="isEventHidden(eventId)"
          class="disabled-event-indicator"
        >
          Hidden
        </div>
      </div>
      <div
        :class="[
          'multiview-table__group',
          `multiview-table__group--type-EVENT`
        ]"
      >
        <EventContainer
          :event="events[eventId]"
        />
      </div>
      <div
        :class="[
          'multiview-table__group',
          `multiview-table__group--type-MARKETS`
        ]"
      >
        <MarketsContainer
          :event="events[eventId]"
        />
      </div>
      <div
        :class="[
          'multiview-table__group',
          `multiview-table__group--type-PARAMS`
        ]"
      >
        <ParametersContainer
          :event="events[eventId]"
        />
      </div>
      <div
        :class="['multiview-table__group', 'multiview-table__group--type-right-actions']"
      >
        <Dropdown
          icon="dots-vertical"
          hide-chevron
          right
        >
          <DropdownItem
            clickable
            @click="openSingleView(eventId)"
          >
            <Icon
              :name="'expand'"
            />
            <span class="dropdown-label">
              Open single view
            </span>
          </DropdownItem>
          <DropdownItem
            v-if="!isEventDisabled(eventId)"
            clickable
            @click="toggleEventVisibility(eventId)"
          >
            <Icon
              :name="isEventHidden(eventId) ? 'eye' : 'eye-off'"
            />
            <span class="dropdown-label">
              {{ isEventHidden(eventId) ? 'Show' : 'Hide' }} event
            </span>
          </DropdownItem>
          <DropdownItem
            clickable
            @click="removeEvent(eventId)"
          >
            <Icon
              class="close-icon"
              :name="'close'"
            />
            <span class="dropdown-label">
              Remove event from list
            </span>
          </DropdownItem>
        </Dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import {
  orderBy, includes, isNil, isArray, filter, pickBy, identity,
} from 'lodash';
import { orderByPinnedEventIds } from '@/services/helpers/multi-view';
import sportIds from '@/services/helpers/sports';
import Spinner from '@/components/common/Spinner';
import EmptyState from '@/components/common/EmptyState';
import PinButton from '@/components/trading-ui/common/PinButton';
import EventContainer from '@/components/trading-ui/multiview/event-container/EventContainer';
import MarketsContainer from '@/components/trading-ui/multiview/markets-container/MarketsContainer';
import ParametersContainer from '@/components/trading-ui/multiview/parameters-container/ParametersContainer';
import SortButton from './SortButton';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import Icon from '@/components/common/Icon';

const { BASEBALL_ID, FOOTBALL_ID } = sportIds;

export default {
  components: {
    Spinner,
    EmptyState,
    PinButton,
    EventContainer,
    MarketsContainer,
    ParametersContainer,
    SortButton,
    Dropdown,
    DropdownItem,
    Icon,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const loading = computed(() => store.getters.multiviewListLoading);
    const events = computed(() => store.getters.multiviewListEventsById);
    const eventIds = computed(() => store.getters.multiviewListEventIds);
    const pinnedEventIds = computed(() => {
      const { pinned } = route.query;
      if (isNil(pinned)) return null;
      if (isArray(pinned)) return pinned;
      return [pinned];
    });
    const sortedEventIds = computed(() => orderBy(eventIds.value, orderByPinnedEventIds(pinnedEventIds.value)));

    const isEventPinned = (eventId) => includes(pinnedEventIds.value, eventId);
    const isEventSuspended = (eventId) => !!store.getters.multiviewListEventsById[eventId]?.operatorEventSuspensionsByEventId?.nodes?.length;
    const isBaseballEvent = (eventId) => {
      const event = store.getters.multiviewListEventsById[eventId];
      return event.sportId === BASEBALL_ID;
    };
    const isFootballEvent = (eventId) => {
      const event = store.getters.multiviewListEventsById[eventId];
      return event.sportId === FOOTBALL_ID;
    };
    const isEventDisabled = (eventId) => store.getters.multiviewListEventsById[eventId].limbo;
    const isEventHidden = (eventId) => store.getters.multiviewListEventsById[eventId]?.isHidden;
    const pinEvent = (eventId) => {
      const newPinnedEventIds = [...pinnedEventIds.value || [], eventId];
      router.replace({
        name: route.name,
        query: pickBy({
          ...route.query,
          pinned: newPinnedEventIds,
        }, identity),
      });
    };
    const unpinEvent = (eventId) => {
      const newPinnedEventIds = filter(pinnedEventIds.value, (id) => eventId !== id);
      router.replace({
        name: route.name,
        query: pickBy({
          ...route.query,
          pinned: newPinnedEventIds,
        }, identity),
      });
    };

    const openSingleView = (eventId) => {
      const routeData = router.resolve({
        name: 'trading-ui',
        params: { eventId },
      });
      window.open(routeData.href, '_blank');
    };

    const toggleEventVisibility = (eventId) => {
      store.dispatch('toggleEventVisibility', { id: eventId, isHidden: isEventHidden(eventId) });
    };

    const removeEvent = inject('removeEvent');

    const drawerEventId = computed(() => route.query.event);

    return {
      loading,
      events,
      eventIds,
      pinnedEventIds,
      sortedEventIds,
      isEventPinned,
      isEventSuspended,
      isBaseballEvent,
      isFootballEvent,
      pinEvent,
      unpinEvent,
      openSingleView,
      removeEvent,
      drawerEventId,
      isEventDisabled,
      isEventHidden,
      toggleEventVisibility,
    };
  },
};
</script>

<style lang="scss">
.multiview-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 4px;
  position: relative;
  padding: 0 16px 16px;
  overflow: auto;

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__no-data {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__row,
  &__group,
  &__cell {
    flex: 1;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__row {
    height: 210px;
    min-height: 210px;
    max-height: 210px;
    width: 1424px;
    max-width: 1424px;
    min-width: 1424px;

    &--is-active {
      box-shadow: 0px 0px 0px 2px #A2BFFF;
      outline: none;
    }

    &--is-large {
      height: 262px;
      min-height: 262px;
      max-height: 262px;
    }

    &--is-mid {
      height: 239px;
      min-height: 239px;
      max-height: 239px;
    }

    .multiview-table__group--type-spacer {
      background-color: #fafafa;
    }

    &:not(&--is-header) {
      .multiview-table__group {
        border-top: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
        border-bottom: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);

        &--type-left-actions {
          border-left: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
          border-right: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
        }

        &--type-right-actions {
          border-right: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
        }
      }

      .multiview-table__group--type-spacer {
        border: none;
      }
    }

    &--is-header {
      height: 32px;
      min-height: 32px;
      max-height: 32px;
      border-radius: var(--radius-medium, 4px);
      border: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
      overflow: hidden;
      color: var(--neutral-text-medium-resting, #6D6D6D);
      text-overflow: ellipsis;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      position: sticky;
      top: 0;
      z-index: 300;

      .multiview-table__group--type-spacer {
        background-color: #fff;
      }

      .multiview-table__cell {
        padding: 0 8px;
      }
    }
  }

  &__group {
    background-color: #fff;
    align-items: flex-start;
    justify-content: center;
    height: 100%;

    &--type-left-actions {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 40px;
      max-width: 40px;
      min-width: 40px;
      padding: 4px 0;
    }

    &--is-suspended {
      background: #FF2E2D;
    }

    &--is-disabled {
      background: $gray400;
      position: relative;

      & .disabled-event-indicator {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
        transform-origin: center;
        text-transform: uppercase;
        color: $gray700;
      }
    }

    &--type-EVENT {
      width: 344px;
      max-width: 334px;
      min-width: 334px;
    }

    &--type-MARKETS {
      width: 720px;
      max-width: 720px;
      min-width: 720px;
    }

    &--type-PARAMS {
      width: 290px;
      max-width: 290px;
      min-width: 290px;
    }

    &--type-right-actions {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 40px;
      max-width: 40px;
      min-width: 40px;

      .dropdown-label {
        display: block;
        margin-left: 4px;
      }

      .icon:not(.close-icon) {
        width: 16px;
        height: 16px;
      }

      .dropdown {
        .button {
          border: unset;
          border-radius: unset;
          padding: 11.5px;
          transition: unset;
        }
        .button:focus {
          border-radius: 4px;
        }
      }
    }
  }

  &__cell {
    height: 100%;
  }
}
</style>
